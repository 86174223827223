import React from 'react'
import Logo from '../assets/Logo'
import { Link } from 'react-router-dom'
import LinkedinIcon from '../assets/icons/LinkedinIcon'
import EmailIcon from '../Dashboard/icons/EmailIcon'
import PhoneIcon from '../Dashboard/icons/PhoneIcon'

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer class="bg-primary-green text-white">
    <section class="flex items-start gap-x-16">
      <div class="company-logo">
          <Link className='flex items-center' to="/">
             <Logo />
            <span>1st Class Material</span>
          </Link>
      </div>
      <div class="ml-auto">
              <h6 className='text-base font-bold font-roboto mb-6'>Address</h6>
              <p className='text-base font-roboto font-normal'>No 1 Umunkanka Str,<br/>
                University of Nigeria
              </p>
      </div>
      <div class="contactus">
            <h6 className='text-base font-bold mb-6 font-roboto'>Contact</h6>
            <div class="email">
              <a href="mailto:info.firstclassmaterial@gmail.com" class="flex gap-x-2 items-center">
                <div class="img-container icon">
                  {/* <img src="./images/email-icon.svg" alt="email"/> */}
                  <EmailIcon/>
                </div>
                <span class="lowercase">info.firstclassmaterial@gmail.com</span>
              </a>
            </div>
            <div class="phone">
              <a href="tel:+2349017912839" class="flex gap-x-2 items-center">
                <div class="img-container icon">
                  {/* <img src="./images/phone-icon.svg" alt="phone"/> */}
                  <PhoneIcon/>
                </div>
                <span>(+234) 901 912 39</span>
              </a>
            </div>
    </div>
    </section>
    
    {/* Draw a horizontal line */}
      <hr className='my-[20px]'/>

      <div class="flex justify-between items-center px-[80px]">
          <div class="copywright-container">
            <small class="copy-wright">
              <span id="current-date"> </span> &copy; {year} All Right Reserved
            </small>
          </div>
          <div class="media-icons">
            <Link 
            className='flex font-roboto' 
            to="https://www.linkedin.com/company/1st-class-material/"
            target='_blank'
            >
              Reach us on linkedin
              <LinkedinIcon/>
            </Link>
          </div>
        </div>

  </footer>
  )
}

export default Footer