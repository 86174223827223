import React from 'react'
import Register from '../Auth/Register'
import Footer from './Footer'
import Main from './Main'

function Home() {
  return (
   <>
   <Register/>
   <Main/>
   <Footer/>
   </>
  )
}

export default Home