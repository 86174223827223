import React, { useState } from 'react'
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../shared/LoadingSpinner";

function VerifyResetToken() {
    const navigate = useNavigate();
    const[searchParams] = useSearchParams();
    const authToken = searchParams.get("authToken");
    const email =  searchParams.get("email")
    const [loading, setLoading] = useState(false);
    const [otpInput, setOtpInput] = useState(Array(5).fill(""))


      // Function to mask part of the email
  const maskEmail = (email) => {
    if (!email) return '';

    const [localPart, domain] = email.split('@');
    
    // Show first 3 letters of the local part and last 3 characters
    const maskedLocalPart = localPart.substring(0, 3) + '*******' + localPart.slice(-2);

    return `${maskedLocalPart}@${domain}`;
  };



  // Handle Change
  const handleChange = (e,i) => {
    setOtpInput((prev) => ([...prev.map((item,index) => (index === i ? e.target.value : item ))]))

     //When  you have one element in one box move to the next box
     if(e.target.value && e.target.nextSibling){
      e.target.nextSibling.focus();
     }
  };
  // Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      otp: otpInput.join(""),
    };
    const url = `${process.env.REACT_APP_VERIFY_RESET_PASSWORD_TOKEN}?authToken=${authToken}`;
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }

      localStorage.setItem("isverified_reset_token",true);
      toast.success(resObj?.message);
      setLoading(false);

      // Login the user after 3 seconds
      setTimeout(() => {
        navigate(`/reset-password?email=${email}`);
      }, 3000);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }; 
  console.log(otpInput.join(""))
  return (
    <section className="auth-section">
       {/* React hot Toast */}
       <Toaster position="top-center" reverseOrder={false}></Toaster>
        <form onSubmit={handleSubmit}>
        <div className="form-container flex">
              <div className="flex-item basis-[100%] md:basis-[72%] lg:basis-[48%] m-auto">
              <Link to="/" className="logo-container">
                <img src={`assets/1stclassmaterial-logo.svg`} alt="logo" />
              </Link>
                <h4 className="text-center">please check your email</h4>
                 <p className='text-center'>Enter the code we sent to {maskEmail(email)}</p>
                 <h5 className='font-[600]'>OTP</h5>
                <div className="form-field otp-field">
                {otpInput.map((data, i) => (
                  <input
                    key={i}
                    type="text"
                    onChange={(e) => handleChange(e, i)}
                    value={data}
                    maxLength={1}
                  />
                ))}
              </div>
               
                
                <div className="form-field otp-field">
                  <button className="cta-btn" disabled={!otpInput[otpInput.length - 1]}>
                     {
                      loading ? <LoadingSpinner/> : "confirm" 
                     }
                  </button>
                </div>

                <div className="not_received">
                    <p className='text-center'>Haven't received it ? <Link className='inline-block font-[600] text-[var(--green-color)]' to="/forgot-password">resend code</Link> </p>
                </div>
    
                </div>
                
            </div>
        </form>
    </section>
  )
}

export default VerifyResetToken