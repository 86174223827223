import React from 'react'

const PhoneIcon = (props) => {
  return (
    <svg 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg" 
    {...props}
    >
    <g clip-path="url(#clip0_3994_32928)">
      <path d="M11.6687 6.66667H13.3354V8.33333C13.3354 8.79167 13.7104 9.16667 14.1687 9.16667C14.627 9.16667 15.002 8.79167 15.002 8.33333V6.66667H16.6687C17.127 6.66667 17.502 6.29167 17.502 5.83333C17.502 5.375 17.127 5 16.6687 5H15.002V3.33333C15.002 2.875 14.627 2.5 14.1687 2.5C13.7104 2.5 13.3354 2.875 13.3354 3.33333V5H11.6687C11.2104 5 10.8354 5.375 10.8354 5.83333C10.8354 6.29167 11.2104 6.66667 11.6687 6.66667ZM16.0104 12.725L13.8937 12.4833C13.3854 12.425 12.8854 12.6 12.527 12.9583L10.9937 14.4917C8.63535 13.2917 6.70202 11.3667 5.50202 9L7.04369 7.45833C7.40202 7.1 7.57702 6.59167 7.51869 6.09167L7.27702 3.99167C7.18535 3.15 6.46869 2.50833 5.62702 2.50833H4.18535C3.24369 2.50833 2.46035 3.29167 2.51869 4.23333C2.96035 11.35 8.65202 17.0333 15.7604 17.475C16.702 17.5333 17.4854 16.75 17.4854 15.8083V14.3667C17.4937 13.5333 16.852 12.8167 16.0104 12.725Z" fill="#FAFAFA"/>
    </g>
    <defs>
      <clipPath id="clip0_3994_32928">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

export default PhoneIcon