import React from 'react'

const Main = () => {
  return (
    <>

    {/* Succeed with  us */}
    <div className='flex bg-neutral-08 rounded-[20px] w-10/12 mx-auto h-[450px] -mb-4 relative z-4'>

    </div>
    </>
  )
}



export default Main