import React from 'react'

const Logo = (props) => {
  return (
    <svg 
    width="57" 
    height="57" 
    viewBox="0 0 57 57" 
    fill="#FAFAFA"
    xmlns="http://www.w3.org/2000/svg" 
    {...props}
    >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4988 6.15771C40.8382 6.15771 50.8414 16.1608 50.8414 28.5003C50.8414 40.8397 40.8382 50.8428 28.4988 50.8428C16.1594 50.8428 6.15625 40.8397 6.15625 28.5003C6.15625 16.1608 16.1594 6.15771 28.4988 6.15771ZM28.4988 8.00755C39.8166 8.00755 48.9915 17.1825 48.9915 28.5003C48.9915 39.818 39.8166 48.993 28.4988 48.993C17.181 48.993 8.00608 39.818 8.00608 28.5003C8.00608 17.1825 17.181 8.00755 28.4988 8.00755Z" />
    <path d="M22.5937 14.8443C23.9163 16.9097 25.8213 17.9786 27.9797 18.8209C28.7841 19.1348 29.6417 19.4237 30.5205 19.7196C30.9257 19.8561 31.3354 19.9941 31.7465 20.1368C33.0608 20.5929 34.4198 21.1065 35.7962 21.7985L35.7962 21.7985C37.7425 22.7768 38.9465 23.9429 39.6165 25.1358C40.285 26.3259 40.4409 27.5756 40.2374 28.7621C40.0838 29.6569 39.726 30.5134 39.2306 31.2738C39.7368 30.1631 39.992 28.7761 39.6251 27.0225C39.1835 24.912 37.7183 23.5664 35.9074 22.5852C34.403 21.7702 32.5933 21.1724 30.8313 20.5905C30.4915 20.4782 30.1534 20.3666 29.8197 20.2541C27.7186 19.5457 25.7835 18.8027 24.4176 17.6793C23.5207 16.9416 22.8666 16.0379 22.5937 14.8443Z" stroke="#FAFAFA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8852 34.5211C34.4565 33.9891 35.9621 32.8854 35.7185 30.8344C35.3527 27.7539 30.8474 26.7204 28.1608 25.5423L23.8359 22.8672C25.4128 29.1117 38.12 27.6301 33.3615 33.8475C32.9883 34.3352 33.26 33.8704 32.8852 34.5211Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1719 43.9723C18.6979 44.7107 22.1952 42.687 24.0556 41.6273C24.8905 41.1517 30.46 38.0877 31.119 37.356C25.8641 37.7588 18.6512 44.3743 16.1719 43.9723Z"/>
  </svg>
  )
}

export default Logo