import React from 'react'

const EmailIcon = (props) => {
  return (
 <svg 
 width="20" 
 height="20" 
 viewBox="0 0 20 20" 
 xmlns="http://www.w3.org/2000/svg" 
 fill="none"
 {...props}
 >
  <g clip-path="url(#clip0_3994_32923)">
    <path d="M16.6641 3.3335H3.33073C2.41406 3.3335 1.6724 4.0835 1.6724 5.00016L1.66406 15.0002C1.66406 15.9168 2.41406 16.6668 3.33073 16.6668H16.6641C17.5807 16.6668 18.3307 15.9168 18.3307 15.0002V5.00016C18.3307 4.0835 17.5807 3.3335 16.6641 3.3335ZM15.8307 15.0002H4.16406C3.70573 15.0002 3.33073 14.6252 3.33073 14.1668V6.66683L9.11406 10.2835C9.65573 10.6252 10.3391 10.6252 10.8807 10.2835L16.6641 6.66683V14.1668C16.6641 14.6252 16.2891 15.0002 15.8307 15.0002ZM9.9974 9.16683L3.33073 5.00016H16.6641L9.9974 9.16683Z" fill="#FAFAFA"/>
  </g>
  <defs>
    <clipPath id="clip0_3994_32923">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default EmailIcon